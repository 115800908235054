import { ActionTypes } from 'actions'

export interface LoginAction {
	type: ActionTypes.login
}

export function login(): LoginAction {
	return {
		type: ActionTypes.login
	}
}

export interface LoginRequestAction {
	googleIdToken: string
	type: ActionTypes.loginRequest
}

export function loginRequest(googleIdToken: string): LoginRequestAction {
	return {
		googleIdToken,
		type: ActionTypes.loginRequest
	}
}

export interface LogoutAction {
	type: ActionTypes.logout
}

export function logout(): LogoutAction {
	return {
		type: ActionTypes.logout
	}
}

export interface SetLoginErrorMsgAction {
	errorMsg: string
	type: ActionTypes.setLoginErrorMsg
}

export function setLoginErrorMsg(errorMsg = ''): SetLoginErrorMsgAction {
	return {
		errorMsg,
		type: ActionTypes.setLoginErrorMsg
	}
}

export interface SetRedirectUrlAction {
	redirectUrl: string
	type: ActionTypes.setRedirectUrl
}

export function setRedirectUrl(redirectUrl = ''): SetRedirectUrlAction {
	return {
		redirectUrl,
		type: ActionTypes.setRedirectUrl
	}
}
