import DassanaRouter from 'components/Router'
import { Dispatch } from 'redux'
import { EmitterEventTypes } from '@dassana-io/web-utils'
import history from 'localHistory'
import { initializeListeners } from 'utils/events'
import PageContainer from 'components/PageContainer'
import { Router } from 'react-router-dom'
import { ThemeContextProvider } from 'context/ThemeContext'
import { useAppContext } from 'context/AppContext'
import { useDispatch } from 'react-redux'
import { useNotification } from '@dassana-io/web-components'
import { logout, LogoutAction } from 'actions'
import React, { FC, useEffect } from 'react'

const App: FC = () => {
	const dispatch: Dispatch<LogoutAction> = useDispatch()
	const { emitter } = useAppContext()
	const { generateNotification } = useNotification()

	const onLogout = (): LogoutAction => dispatch(logout())

	useEffect(() => {
		emitter.on(EmitterEventTypes.logout, onLogout)

		return () => emitter.off(EmitterEventTypes.logout, onLogout)
	})

	useEffect(() => {
		// https://stackoverflow.com/a/76163164
		window.addEventListener('error', e => {
			if (e.message === 'ResizeObserver loop limit exceeded') {
				const resizeObserverErrDiv = document.getElementById(
					'webpack-dev-server-client-overlay-div'
				)
				const resizeObserverErr = document.getElementById(
					'webpack-dev-server-client-overlay'
				)

				if (resizeObserverErr) {
					resizeObserverErr.setAttribute('style', 'display: none')
				}

				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute('style', 'display: none')
				}
			}
		})
	}, [])

	useEffect(() => {
		initializeListeners(emitter, generateNotification)
	}, [emitter, generateNotification])

	return (
		<Router history={history}>
			<ThemeContextProvider>
				<PageContainer>
					<DassanaRouter />
				</PageContainer>
			</ThemeContextProvider>
		</Router>
	)
}

export default App
