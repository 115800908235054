import { Service } from 'api'
import { Action, ActionTypes } from 'actions'

export interface ServiceMapState {
	loading: boolean
	map: Service[]
}

export const initialState: ServiceMapState = {
	loading: false,
	map: []
}

const ServiceMapReducer = (state = initialState, action: Action) => {
	switch (action.type) {
		case ActionTypes.setServiceMap: {
			return { ...state, map: action.serviceMap }
		}

		case ActionTypes.setServiceMapLoading: {
			return { ...state, loading: action.loading }
		}

		default: {
			return state
		}
	}
}

export default ServiceMapReducer
