import { ActionTypes } from 'actions'
import cleanupSessionSaga from './cleanupSessionSaga'
import detectTokenPresenceSaga from './detectTokenPresenceSaga'
import fetchProfileSaga from './fetchProfileSaga'
import fetchServiceMapAndRedirectSaga from './fetchServiceMapAndRedirectSaga'
import inactivityTimeoutSaga from './inactivityTimeoutSaga'
import loginSaga from './loginSaga'
import refreshTokenSaga from './refreshTokenSaga'
import setupSessionSaga from './setupSessionSaga'
import { all, cancel, fork, take } from 'redux-saga/effects'

export default function* rootSaga(): Generator<any, any, any> {
	// [not blocking]. like call but not blocking. run the task and go to the next step.
	// setupSessionSaga checks if user is logged in and redirects to correct url
	yield fork(setupSessionSaga)

	while (true) {
		// run loginSaga but because the first yield is take('loginRequest'), if a user is already logged in, it'll get blocked on that line
		const loginSagaTask = yield fork(loginSaga)
		// from the previous fork(setupSessionSaga), if user's token exists, 'login' action will have been dispatched. so it'll go to next line and cancel the loginSagaTask
		yield take(ActionTypes.login)

		yield cancel(loginSagaTask)

		const [fetchServiceMapAndRedirectTask, fetchProfileTask] = yield all([
			fork(fetchServiceMapAndRedirectSaga),
			fork(fetchProfileSaga)
		])

		const [
			detectTokenPresenceTask,
			inactivityTimeoutTask,
			refreshTokenTask
		] = yield all([
			fork(detectTokenPresenceSaga),
			fork(inactivityTimeoutSaga),
			fork(refreshTokenSaga)
		])

		yield take(ActionTypes.logout)

		yield all([
			cancel(fetchServiceMapAndRedirectTask),
			cancel(fetchProfileTask),
			cancel(detectTokenPresenceTask),
			cancel(inactivityTimeoutTask),
			cancel(refreshTokenTask),
			fork(cleanupSessionSaga)
		])
	}
}
