import { Dispatch } from 'redux'
import { getDataTestAttributeProp } from '@dassana-io/web-components'
import { useDispatch } from 'react-redux'
import { loginRequest, LoginRequestAction } from 'actions'
import React, { useCallback, useEffect } from 'react'

const CLIENT_ID =
	'14818435192-pngbo0vtg8jf8hpcgienqp825764mei2.apps.googleusercontent.com'

const GOOGLE_BUTTON_ID = 'google-login-btn'

const LoginButton: React.FC = () => {
	const dispatch: Dispatch<LoginRequestAction> = useDispatch()

	const onGoogleAuthChange = useCallback(
		({ credential }: google.accounts.id.CredentialResponse) =>
			dispatch(loginRequest(credential)),
		[dispatch]
	)

	useEffect(() => {
		if (window.google) {
			google.accounts.id.initialize({
				callback: onGoogleAuthChange,
				client_id: CLIENT_ID
			})

			const btnContainerDiv = document.getElementById(GOOGLE_BUTTON_ID)

			if (btnContainerDiv) {
				google.accounts.id.renderButton(btnContainerDiv, {
					logo_alignment: 'left',
					size: 'large',
					theme: 'filled_blue',
					type: 'standard',
					width: '300px'
				})
			}
		}
	}, [onGoogleAuthChange])

	return (
		<div
			id={GOOGLE_BUTTON_ID}
			{...getDataTestAttributeProp('google-sign-in-button')}
		/>
	)
}

export default LoginButton
