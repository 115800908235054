import { createUseStyles } from 'react-jss'
import { NAV_DIMENSIONS } from 'utils/constants'
import NavLinks from './NavLinks'
import { ReactComponent as NavLogoDark } from 'assets/nav_logo_dark.svg'
import { ReactComponent as NavLogoLight } from 'assets/nav_logo_light.svg'
import { QueryParams } from '@dassana-io/web-utils'
import useThemeContext from 'context/ThemeContext'
import React, { FC } from 'react'
import { styleguide, Theme, ThemeType } from '@dassana-io/web-components'

const { flexCenter, flexDown, spacing } = styleguide

interface StyleProps {
	isViewableDoc: boolean
	theme: Theme
}

const useStyles = createUseStyles({
	container: {
		...flexDown,
		background: ({ theme }: StyleProps) => theme.background.secondary,
		borderRight: ({ theme }: StyleProps) => `1px solid ${theme.border}`,
		display: ({ isViewableDoc }: StyleProps) =>
			isViewableDoc ? 'none' : 'flex',
		height: '100%',
		transition: 'width 0.5s',
		width: NAV_DIMENSIONS
	},
	header: {
		...flexCenter,
		height: NAV_DIMENSIONS,
		marginBottom: spacing['m+']
	}
})

const Nav: FC = () => {
	const { theme, themeType } = useThemeContext()

	const queryParams = new URLSearchParams(window.location.search)
	const isForPDF = !!queryParams.get(QueryParams.forPDF)
	const isReadonly = !!queryParams.get(QueryParams.readonly)
	const isViewableDoc = isForPDF || isReadonly

	const classes = useStyles({ isViewableDoc, theme })

	return (
		<div className={classes.container}>
			<span className={classes.header}>
				{themeType === ThemeType.dark ? (
					<NavLogoDark height={32} />
				) : (
					<NavLogoLight height={32} />
				)}
			</span>
			<NavLinks />
		</div>
	)
}

export default Nav
