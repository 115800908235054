import history from 'localHistory'
import { all, getContext, put } from 'redux-saga/effects'
import { EMITTER, LAST_ACTIVE, TOKEN } from 'sagas/constants'
import {
	Emitter,
	EmitterEventTypes,
	ONBOARDING_DEFAULT_STEP
} from '@dassana-io/web-utils'
import { setRedirectUrl, setServiceMap } from 'actions'

export default function* cleanupSessionSaga() {
	const emitter: Emitter = yield getContext(EMITTER)

	emitter.emit(EmitterEventTypes.loggedOut, 'logging out')

	history.push('/')

	yield all([put(setRedirectUrl()), put(setServiceMap())])

	localStorage.removeItem(LAST_ACTIVE)
	localStorage.removeItem(ONBOARDING_DEFAULT_STEP)
	localStorage.removeItem(TOKEN)
}
