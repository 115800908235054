import min from 'lodash/min'
import NavLink from './NavLink'
import { Service } from 'api'
import { StoreState } from 'reducers'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getNavbarServices, getServiceName, processServiceMap } from './utils'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

export type ValidPagesMap = Record<string, string>

const NavLinks: FC = () => {
	const [isAltPressed, setIsAltPressed] = useState(false)
	const history = useHistory()
	const serviceMap = useSelector(
		(state: StoreState): Service[] => state.serviceMap.map
	)

	const serviceMapObject = useMemo(
		() => processServiceMap(serviceMap),
		[serviceMap]
	)

	/**
	 * Regex that checks if the key code from the keyboard event corresponds to a valid page
	 * Key codes for the number keys (1-9) are returned as Digit1, Digit2, etc.
	 * Since profile is not rendered as a NavLink, we filter it out from the service map.
	 * As a result, valid key codes range from 1 to serviceMap.length - 1
	 *
	 * Ex: If the service map stored in the reducer had a length of 3, the resulting regex would be /Digit[1-2]/
	 *
	 * NOTE: We only want regex to be a range if there is more than 1 link in the sidebar. If serviceMap has a length
	 * of 2, there would only be one link in the sidebar so the resulting regex would be /Digit[1]/
	 */
	const isValidPageKeyRegex = useMemo(
		() =>
			new RegExp(
				`Digit[${
					serviceMap.length > 2
						? `1-${min([9, serviceMap.length - 1])}`
						: 1
				}]`
			),
		[serviceMap.length]
	)

	const validPageSwitches: ValidPagesMap = useMemo(() => ({}), [])

	const switchPage = useCallback(
		(key: string): void => {
			if (!validPageSwitches[key]) return // Prevents switching to a `undefined` page

			const page = getServiceName(validPageSwitches[key])

			history.push(`/${page}`)
		},
		[history, validPageSwitches]
	)

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			// Navigate the user to the appropriate page if they press the alt key and a valid digit code that corresponds
			// to a page
			if (event.altKey && isValidPageKeyRegex.test(event.code)) {
				const { code } = event

				// Pull the last character off of the valid event.code to use as the page key
				// Ex: If the event.code was 'Digit1', '1' is passed as the key
				switchPage(code[code.length - 1])
			}
			// Show page key hint if only the alt key is pressed
			else if (event.altKey) {
				setIsAltPressed(true)
			}
		},
		[isValidPageKeyRegex, switchPage]
	)

	const handleKeyUp = useCallback((event: KeyboardEvent) => {
		if (event.key === 'Alt') {
			setIsAltPressed(false)
		}
	}, [])

	const handleWindowBlur = useCallback(() => setIsAltPressed(false), [])

	useEffect(() => {
		if (serviceMap.length > 1) {
			window.addEventListener('keydown', handleKeyDown)
			window.addEventListener('keyup', handleKeyUp)
			window.addEventListener('blur', handleWindowBlur)

			return () => {
				window.removeEventListener('keydown', handleKeyDown)
				window.removeEventListener('keyup', handleKeyUp)
				window.removeEventListener('blur', handleWindowBlur)
			}
		}
	}, [handleKeyDown, handleKeyUp, handleWindowBlur, serviceMap.length])

	if (!serviceMap.length) return <></>

	return (
		<>
			{getNavbarServices(serviceMapObject).map(({ serviceName }, i) => {
				const pageKey = i + 1
				// Store page key as i + 1 because we want the first item rendered in the Nav to be easily accessible with Alt + 1 instead of Alt + 0
				validPageSwitches[pageKey] = serviceName

				serviceName = getServiceName(serviceName)

				return (
					<NavLink
						key={serviceName}
						pageKey={pageKey}
						serviceName={serviceName}
						showPageKeyHint={isAltPressed}
					/>
				)
			})}
		</>
	)
}

export default NavLinks
