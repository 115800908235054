import { isDarkMode } from 'utils/common'
import { useAppContext } from 'context/AppContext'
import { createCtx, EmitterEventTypes } from '@dassana-io/web-utils'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Theme, themes, ThemeType } from '@dassana-io/web-components'

interface ThemeContextType {
	setThemeType: (value: ThemeType) => void
	theme: Theme
	themeType: ThemeType
}

export interface ThemeContextProviderProps {
	children: ReactNode
}

export const THEME = 'theme'

const defaultState = ThemeType.dark

const [useThemeContext, ThemeCtxProvider] = createCtx<ThemeContextType>()

export const ThemeContextProvider: FC<ThemeContextProviderProps> = ({
	children
}: ThemeContextProviderProps) => {
	const { emitter } = useAppContext()
	const [themeType, setThemeType] = useState(defaultState)
	const [theme, setTheme] = useState(themes.light)

	useEffect(() => {
		const storedThemeType = window.localStorage.getItem(THEME)

		const currentThemeType = storedThemeType
			? (storedThemeType as ThemeType)
			: defaultState

		if (isDarkMode(currentThemeType)) {
			window.document.body.classList.add(currentThemeType)
		}

		setThemeType(currentThemeType)
		setTheme(themes[currentThemeType])
	}, [])

	useEffect(() => {
		window.localStorage.setItem(THEME, themeType)
		emitter.emit(EmitterEventTypes.themeUpdated, themeType)

		if (isDarkMode(themeType)) {
			window.document.body.classList.add(themeType)
		} else {
			window.document.body.classList.remove(ThemeType.dark)
		}

		setTheme(themes[themeType])
	}, [emitter, themeType])

	return (
		<ThemeCtxProvider value={{ setThemeType, theme, themeType }}>
			{children}
		</ThemeCtxProvider>
	)
}

export default useThemeContext
