import { GetProfileV2 } from 'api'
import { Action, ActionTypes } from 'actions'

export interface ProfileState extends GetProfileV2 {
	profileApiError?: boolean
}

const ProfileReducer = (
	state: ProfileState = { profileApiError: false } as ProfileState,
	action: Action
) => {
	switch (action.type) {
		case ActionTypes.setProfileInfo: {
			return action.profileInfo
		}

		case ActionTypes.setProfileApiError: {
			return {
				...state,
				profileApiError: action.profileApiError
			}
		}

		default: {
			return state
		}
	}
}

export default ProfileReducer
