import { createUseStyles } from 'react-jss'
import kebabCase from 'lodash/kebabCase'
import { supportMenuPalette } from './styles'
import { useAppContext } from 'context/AppContext'
import { useLocation } from 'react-router-dom'
import { getAppEnv, useTheme } from '@dassana-io/web-utils'
import { Link, styleguide, ThemeType } from '@dassana-io/web-components'
import React, { useCallback } from 'react'

const { flexDown, spacing } = styleguide

const useStyles = createUseStyles({
	container: {
		...flexDown,
		background: (theme: ThemeType) =>
			supportMenuPalette.container[theme].background,
		borderRadius: 0
	},
	menuItem: {
		'&:hover': {
			background: (theme: ThemeType) =>
				supportMenuPalette.menuItem[theme].hoverBackground
		},
		color: (theme: ThemeType) =>
			`${supportMenuPalette.menuItem[theme].color} !important`,
		cursor: 'pointer',
		padding: `${spacing.s}px ${spacing.m}px`
	}
})

const SupportMenu = () => {
	const { emitter } = useAppContext()
	const theme = useTheme(emitter)

	const classes = useStyles(theme)

	const location = useLocation()

	const getRelevantDocsUrl = useCallback(() => {
		const initialPath = location.pathname.split('/')[1]

		return `https://docs.dassana.cloud/${kebabCase(initialPath)}/intro`
	}, [location.pathname])

	const supportMenuConfig = [
		{ display: 'Docs', url: getRelevantDocsUrl() },
		{
			display: 'Slack Community',
			url: 'https://join.slack.com/t/dassanacommunity/shared_invite/zt-teo6d5ed-xkWDNSaH4m6pC8PAJnrD8g'
		},
		{
			display: 'Status',
			url: `https://status.dassana.${getAppEnv()}`
		},
		{
			display: 'Release Notes',
			url: 'https://dassanaio.notion.site/Dassana-Release-Notes-4ec95e3f72bc43aab0adb1ac534e0ec4'
		}
	]

	return (
		<div className={classes.container}>
			{supportMenuConfig.map(({ display, url }) => (
				<Link
					classes={[classes.menuItem]}
					dataTag={`support-menu-${display}`}
					href={url}
					key={display}
					target='_blank'
					underline={false}
				>
					{display}
				</Link>
			))}
		</div>
	)
}

export default SupportMenu
