import MagicLinkConfirmation from './MagicLinkConfirmation'
import MagicLinkExpired from './MagicLinkExpired'
import { useMagicLinkContext } from 'context/MagicLinkContext'
import React, { FC } from 'react'

const MagicLinkFollowup: FC = () => {
	const { isErrorState } = useMagicLinkContext()

	if (isErrorState) return <MagicLinkExpired />

	return <MagicLinkConfirmation />
}

export default MagicLinkFollowup
