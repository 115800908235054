import { getApiUrlFromServiceMap } from 'utils/common'
import history from 'localHistory'
import { QUERY_SERVICE_NAME } from 'utils/constants'
import { ActionTypes, fetchProfileRequest, SetProfileInfoAction } from 'actions'
import { all, call, put, race, select, take } from 'redux-saga/effects'
import {
	api,
	buildBrowserUrl,
	ONBOARDING_DEFAULT_STEP,
	OnboardingStepNum,
	Services
} from '@dassana-io/web-utils'
import {
	BASE_URL,
	GetProfileV2,
	Org,
	ORG_ENDPOINT,
	QUERY_READY_ENDPOINT,
	QueryableResponse,
	Service,
	Tenant,
	TENANT_ENDPOINT
} from 'api'

export async function fetchEulaAccepted(): Promise<boolean> {
	const {
		data: { eulaAccepted }
	} = await api(BASE_URL).get<Tenant>(TENANT_ENDPOINT)

	return eulaAccepted
}

export function* eulaStatusSaga() {
	try {
		const accepted: boolean = yield call(fetchEulaAccepted)

		return accepted
	} catch (error) {
		return false
	}
}

export async function fetchIsQueryServiceReady(
	serviceMap: Service[]
): Promise<boolean> {
	try {
		const queryApiUrl = getApiUrlFromServiceMap(
			serviceMap,
			QUERY_SERVICE_NAME
		)

		const {
			data: { isQueryable = false }
		} = await api().get<QueryableResponse>(QUERY_READY_ENDPOINT, {
			baseURL: queryApiUrl,
			timeout: 5000
		})

		return isQueryable
	} catch (error) {
		return false
	}
}

export function* queryServiceStatusSaga() {
	try {
		const serviceMap: Service[] = yield select(
			state => state.serviceMap.map
		)

		const ready: boolean = yield call(fetchIsQueryServiceReady, serviceMap)

		return ready
	} catch (error) {
		return false
	}
}

export async function fetchOrg(serviceMap: Service[]): Promise<Org> {
	const orgManagerApiUrl = getApiUrlFromServiceMap(
		serviceMap,
		Services.orgManager
	)

	const { data } = await api().get<Org>(ORG_ENDPOINT, {
		baseURL: orgManagerApiUrl
	})

	return data
}

export function* pageRedirectSaga(onboarding = false) {
	const isQueryServiceReady: boolean = yield call(queryServiceStatusSaga)

	const defaultUrl = buildBrowserUrl({
		pathname: isQueryServiceReady ? Services.visualize : Services.appStore
	})

	const redirectUrl: string = onboarding
		? buildBrowserUrl({ pathname: Services.onboarding })
		: yield select(state => state.auth.redirectUrl)

	history.push(redirectUrl ? redirectUrl : defaultUrl)
}

export const getOnboardingDefaultActiveStep = (
	eulaAccepted: boolean,
	profileInfo: GetProfileV2,
	orgInfo: Org
) => {
	const { firstName, lastName, personas, workEnd, timezone, workStart } =
		profileInfo

	const {
		endUserLicenseAgreement,
		userName,
		userWorkHours,
		userPersona,
		orgName
	} = OnboardingStepNum

	if (!eulaAccepted) return endUserLicenseAgreement
	else if (!firstName || !lastName) return userName
	else if (!timezone?.id || workEnd! < 0 || workStart! < 0)
		return userWorkHours
	else if (!personas || !personas.length) return userPersona
	// onboarding checks for role so this should put the user in the right step if they're the first admin and left onboarding process after filling all the profile info
	else if (!orgInfo.name) return orgName
	// return 0 if user is already onboarded
	return 0
}

export function* onboardingRedirectSaga() {
	try {
		const serviceMap: Service[] = yield select(
			state => state.serviceMap.map
		)

		const { error, success } = yield race({
			error: take(ActionTypes.setProfileApiError),
			success: all([
				call(fetchOrg, serviceMap),
				take(ActionTypes.setProfileInfo),
				put(fetchProfileRequest())
			])
		})

		const eulaAccepted: boolean = yield call(eulaStatusSaga)

		if (!error) {
			const [orgInfo, { profileInfo }]: [Org, SetProfileInfoAction] =
				success

			const redirectToOnboarding = shouldRedirectToOnboarding(
				eulaAccepted,
				profileInfo,
				orgInfo
			)

			yield call(pageRedirectSaga, redirectToOnboarding)
		} else {
			yield call(pageRedirectSaga)
		}
	} catch (error: any) {
		let redirectToOnboarding = false

		if (error.response && error.response.status === 403) {
			const eulaAccepted: boolean = yield call(eulaStatusSaga)
			const profileInfo: GetProfileV2 = yield select(
				state => state.profile
			)

			redirectToOnboarding = shouldRedirectToOnboarding(
				eulaAccepted,
				profileInfo,
				{} as Org
			)
		}

		yield call(pageRedirectSaga, redirectToOnboarding)
	}
}

const shouldRedirectToOnboarding = (
	eulaAccepted: boolean,
	profileInfo: GetProfileV2,
	orgInfo: Org
) => {
	/**
	 * 1. getOnboardingDefaultActiveStep checks if a member is onboarded or not based on profileInfo and orgInfo.
	 * 2. It also gets the default active step in the onboarding process.
	 */
	const onboardingDefaultStep = getOnboardingDefaultActiveStep(
		eulaAccepted,
		profileInfo,
		orgInfo
	)

	if (onboardingDefaultStep) {
		// onboardingDefaultStep is then added to localStorage so web-onboarding microfrontend can put the member on the right onboarding step.
		localStorage.setItem(
			ONBOARDING_DEFAULT_STEP,
			JSON.stringify(onboardingDefaultStep)
		)
	} else {
		localStorage.removeItem(ONBOARDING_DEFAULT_STEP)
	}

	return onboardingDefaultStep > 0
}
