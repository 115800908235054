import { createUseStyles } from 'react-jss'
import { styleguide } from '@dassana-io/web-components'
import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'

const {
	colors: { blacks }
} = styleguide

const useStyles = createUseStyles({
	shortcut: {
		'&:not(:last-of-type)': {
			marginRight: 4
		},
		background: blacks.base,
		borderRadius: 4,
		fontWeight: 700,
		padding: 4
	},
	tooltipTitle: {
		paddingRight: 8
	}
})

interface NavLinkTooltipTitleProps {
	pageKey: number
	serviceName: string
}

const NavLinkTooltipTitle: FC<NavLinkTooltipTitleProps> = ({
	pageKey,
	serviceName
}: NavLinkTooltipTitleProps) => {
	const { t } = useTranslation()

	const classes = useStyles()

	return (
		<span>
			<span className={classes.tooltipTitle}>{t(serviceName)}</span>
			<span className={classes.shortcut}>
				{window.navigator.platform.indexOf('Mac') > -1 ? '⌥' : 'Alt'}
			</span>
			<span className={classes.shortcut}>{pageKey}</span>
		</span>
	)
}

export default NavLinkTooltipTitle
