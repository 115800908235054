import { AppContextProvider } from 'context/AppContext'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { Emitter } from '@dassana-io/web-utils'
import { NotificationProvider } from '@dassana-io/web-components'
import { Provider } from 'react-redux'
import React from 'react'
import reducers from 'reducers'
import reduxThunk from 'redux-thunk'
import rootSaga from 'sagas/rootSaga'
import { applyMiddleware, createStore } from 'redux'

export const dassanaEmitter = new Emitter()

interface Props {
	children: JSX.Element
	initialState?: Object
}

const Root = ({ children, initialState = {} }: Props) => {
	const sagaMiddleware = createSagaMiddleware({
		context: { emitter: dassanaEmitter }
	})

	const middleware = [reduxThunk, sagaMiddleware]

	const store = createStore(
		reducers,
		initialState,
		composeWithDevTools(applyMiddleware(...middleware))
	)

	sagaMiddleware.run(rootSaga)

	return (
		<Provider store={store}>
			<AppContextProvider value={{ emitter: dassanaEmitter }}>
				<NotificationProvider>{children}</NotificationProvider>
			</AppContextProvider>
		</Provider>
	)
}

export default Root
