import axios from 'axios'
import { TOKEN } from 'sagas/constants'
import {
	ActionTypes,
	login,
	LoginRequestAction,
	setLoginErrorMsg
} from 'actions'
import { BASE_URL, ErrorInfo, JWT, JWT_ENDPOINT } from 'api'
import { call, put, take } from 'redux-saga/effects'

export async function fetchToken(googleIdToken: string): Promise<string> {
	const {
		data: { id_token }
	} = await axios.post<JWT>(
		JWT_ENDPOINT,
		{
			id_token: googleIdToken
		},
		{ baseURL: BASE_URL }
	)

	return id_token
}

export default function* loginSaga() {
	while (true) {
		// take is [blocking]. wait for dispatched action. the result will be the object returned by loginRequest(googleIdToken)
		const { googleIdToken }: LoginRequestAction = yield take(
			ActionTypes.loginRequest
		)

		try {
			// call is [blocking]. usually for async fns, api calls. call fetchToken async fn with arg googleIdToken and set the returned value to token
			const token: string = yield call(fetchToken, googleIdToken)

			// set the received token into localStorage
			localStorage.setItem(TOKEN, token)

			// [not blocking]. schedule dispatching of action
			yield put(login())
		} catch (error: any) {
			const {
				response: { data }
			} = error

			const { key, msg = '' }: ErrorInfo = data

			yield put(setLoginErrorMsg(msg ? msg : key))
		}
	}
}
