import { Emitter, ev, NotificationEvent } from '@dassana-io/web-utils'
import {
	NotificationConfig,
	NotificationTypes
} from '@dassana-io/web-components'

const initializeListeners = (
	emitter: Emitter,
	generateNotification: (notification: NotificationConfig) => void
) => {
	const eventTypes = Object.keys(NotificationTypes) as ev[]

	eventTypes.forEach(eventType =>
		emitter.on(eventType, (e: NotificationEvent): void =>
			generateNotification({
				duration: 5000,
				message: e.message,
				type: eventType
			})
		)
	)
}

export { initializeListeners }
