import { getAppEnv } from '@dassana-io/web-utils'

export const getRecaptchaApiKey = (): string => {
	const env = getAppEnv()

	return env === 'dev'
		? process.env.REACT_APP_RECAPTCHA_V2_API_KEY_DEV!
		: process.env.REACT_APP_RECAPTCHA_V2_API_KEY_PROD!
}

export const getBaseUrl = () => {
	const { host } = window.location

	if (host.includes('localhost')) return 'dassana.dev'

	return host.replace('console.', '')
}
