import { createUseStyles } from 'react-jss'
import { Dispatch } from 'redux'
import { getRefreshToken } from 'sagas/refreshTokenSaga'
import LoginActions from 'components/LoginActions'
import LoginDark from 'assets/login_dark.jpg'
import LoginLight from 'assets/login_light.jpg'
import { MagicLinkContextProvider } from 'context/MagicLinkContext'
import { TOKEN } from 'sagas/constants'
import { unstable_batchedUpdates } from 'react-dom'
import { useAppContext } from 'context/AppContext'
import { useDispatch } from 'react-redux'
import useThemeContext from 'context/ThemeContext'
import { useTranslation } from 'react-i18next'
import {
	getAppEnv,
	handleAjaxErrors,
	useQueryParams
} from '@dassana-io/web-utils'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { Link, styleguide, Theme, ThemeType } from '@dassana-io/web-components'
import { login, LoginAction } from 'actions'
import React, { useEffect, useState } from 'react'

const {
	colors: { whites },
	flexCenter,
	flexDown,
	font,
	fontWeight,
	spacing
} = styleguide

interface StyleProps {
	theme: Theme
	themeType: ThemeType
}

const useStyles = createUseStyles({
	container: {
		...flexCenter,
		...flexDown,
		backgroundImage: ({ themeType }: StyleProps) =>
			`url(${themeType === ThemeType.dark ? LoginDark : LoginLight})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		color: whites.base,
		height: '100%',
		width: '100%'
	},
	copyright: {
		...font.label,
		color: ({ theme }: StyleProps) => theme.text.primary,
		fontWeight: fontWeight.light,
		paddingTop: spacing.xl
	}
})

const LandingPage: React.FC = () => {
	const dispatch: Dispatch<LoginAction> = useDispatch()

	const { emitter } = useAppContext()
	const { theme, themeType } = useThemeContext()
	const { t } = useTranslation()
	const classes = useStyles({ theme, themeType })

	const { getParam } = useQueryParams()
	const token = getParam(TOKEN)
	const [email, setEmail] = useState('')
	const [isErrorState, setIsErrorState] = useState(false)
	const [magicLinkSent, setMagicLinkSent] = useState(false)

	useEffect(() => {
		const tokenLogin = async () => {
			if (token) {
				const tokenString = JSON.stringify(token)

				try {
					const decoded = jwtDecode<JwtPayload>(tokenString)

					if (decoded) {
						localStorage.setItem(TOKEN, JSON.parse(tokenString))

						await getRefreshToken()

						dispatch(login())
					}
				} catch (error: any) {
					localStorage.removeItem(TOKEN)

					const status = error.response?.status

					if (status && status === 401) {
						unstable_batchedUpdates(() => {
							setMagicLinkSent(true) // Setting this to `true` will show the followup view
							setIsErrorState(true)
						})
					} else {
						handleAjaxErrors(error, emitter)
					}
				}
			}
		}

		tokenLogin()
	}, [dispatch, emitter, token])

	return (
		<div className={classes.container}>
			<MagicLinkContextProvider
				value={{
					email,
					isErrorState,
					magicLinkSent,
					setEmail,
					setIsErrorState,
					setMagicLinkSent
				}}
			>
				<LoginActions />
				<div className={classes.copyright}>
					{t('copyright')} /{' '}
					<Link
						href={`https://status.dassana.${getAppEnv()}`}
						target='_blank'
					>
						Status
					</Link>
				</div>
			</MagicLinkContextProvider>
		</div>
	)
}

export default LandingPage
