import { getApiUrlFromServiceMap } from 'utils/common'
import { ActionTypes, setProfileApiError, setProfileInfo } from 'actions'
import { api, Services } from '@dassana-io/web-utils'
import { call, put, select, take } from 'redux-saga/effects'
import { GetProfileV2, PROFILE_ENDPOINT, Service } from 'api'

export async function fetchProfile(
	serviceMap: Service[]
): Promise<GetProfileV2> {
	const profileApiUrl = getApiUrlFromServiceMap(serviceMap, Services.profile)

	const { data } = await api().get<GetProfileV2>(PROFILE_ENDPOINT, {
		baseURL: profileApiUrl
	})

	return data
}

export default function* fetchProfileSaga() {
	while (true) {
		// take is [blocking]. wait for dispatched action
		yield take(ActionTypes.fetchProfileRequest)

		try {
			const serviceMap: Service[] = yield select(
				state => state.serviceMap.map
			)

			// [blocking]. call fetchProfile with serviceMap as arg
			const profile: GetProfileV2 = yield call(fetchProfile, serviceMap)

			// [not blocking]. schedule dispatching of action
			yield put(setProfileInfo(profile))
		} catch (error: any) {
			yield put(setProfileApiError(true))
		}
	}
}
