import { ActionTypes } from 'actions/types'
import { GetProfileV2 } from 'api/profile'

export interface FetchProfileRequestAction {
	type: ActionTypes.fetchProfileRequest
}

export function fetchProfileRequest(): FetchProfileRequestAction {
	return {
		type: ActionTypes.fetchProfileRequest
	}
}

export interface SetProfileApiErrorAction {
	profileApiError: boolean
	type: ActionTypes.setProfileApiError
}

export function setProfileApiError(profileApiError: boolean) {
	return {
		profileApiError,
		type: ActionTypes.setProfileApiError
	}
}

export interface SetProfileInfoAction {
	profileInfo: GetProfileV2
	type: ActionTypes.setProfileInfo
}

export function setProfileInfo(
	profileInfo: GetProfileV2 = {} as GetProfileV2
): SetProfileInfoAction {
	return {
		profileInfo,
		type: ActionTypes.setProfileInfo
	}
}
