import AccountInfoMenu from './AccountInfoMenu'
import { createUseStyles } from 'react-jss'
import { Dispatch } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import ProfileAvatar from './ProfileAvatar'
import { ProfileState } from 'reducers/profile'
import { StoreState } from 'reducers'
import SupportMenu from './SupportMenu'
import { useAppContext } from 'context/AppContext'
import { EmitterEventTypes, QueryParams } from '@dassana-io/web-utils'
import { faGear, faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import { fetchProfileRequest, FetchProfileRequestAction } from 'actions'
import { Popover, styleguide } from '@dassana-io/web-components'
import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

const { flexAlignCenter, spacing } = styleguide

const { offsetProfileIcon, profileUpdated } = EmitterEventTypes

const TOP_NAV_RIGHT_SPACING = spacing.l

interface StyleProps {
	iconOffset: number
	isViewableDoc: boolean
}

const useStyles = createUseStyles({
	accountInfoContainer: {
		...flexAlignCenter,
		cursor: 'pointer'
	},
	container: {
		'& > span:not(:last-child)': {
			marginRight: spacing.l
		},
		...flexAlignCenter,
		display: ({ isViewableDoc }: StyleProps) =>
			isViewableDoc ? 'none' : 'flex',
		position: 'absolute',
		right: ({ iconOffset }: StyleProps) =>
			TOP_NAV_RIGHT_SPACING + iconOffset,
		top: spacing.m,
		zIndex: 1000
	},
	popover: {
		'&.ant-popover > .ant-popover-content > .ant-popover-inner': {
			backgroundColor: 'transparent !important'
		}
	},
	supportMenuContainer: {
		cursor: 'pointer'
	}
})

const Header: React.FC = () => {
	const dispatch: Dispatch<FetchProfileRequestAction> = useDispatch()
	const { emitter } = useAppContext()
	const profileInfo = useSelector(
		(state: StoreState): ProfileState => state.profile
	)
	const topNavRef = useRef<HTMLDivElement>(null)
	const [topNavWidth, setTopNavWidth] = useState(0)
	const [iconOffset, setIconOffset] = useState(0)
	const [accountMenuOpen, setAccountMenuOpen] = useState(false)

	const queryParams = new URLSearchParams(window.location.search)
	const isForPDF = !!queryParams.get(QueryParams.forPDF)
	const isReadonly = !!queryParams.get(QueryParams.readonly)
	const isViewableDoc = isForPDF || isReadonly

	const classes = useStyles({ iconOffset, isViewableDoc })

	const { firstName = '' } = profileInfo

	const closeMenu = useCallback(() => {
		setAccountMenuOpen(false)
	}, [])

	const fetchProfile = useCallback(
		(): FetchProfileRequestAction => dispatch(fetchProfileRequest()),
		[dispatch]
	)

	const setOffset = useCallback(
		({ width }: { width: number }) => setIconOffset(width),
		[]
	)

	const emitTopNavWidth = useCallback(() => {
		emitter.emit(EmitterEventTypes.getOrchestratorTopNavWidth, {
			width: topNavWidth
		})
	}, [topNavWidth, emitter])

	const handleAccountMenuOpen = useCallback(() => {
		setAccountMenuOpen(!accountMenuOpen)
	}, [accountMenuOpen])

	useEffect(
		() =>
			emitter.on(
				EmitterEventTypes.triggerOrchestratorTopNav,
				emitTopNavWidth
			),
		[emitter, emitTopNavWidth]
	)

	useEffect(() => {
		emitter.on(profileUpdated, fetchProfile)

		return () => emitter.off(profileUpdated, fetchProfile)
	}, [emitter, fetchProfile])

	useEffect(() => {
		emitter.on(offsetProfileIcon, setOffset)

		return () => emitter.off(offsetProfileIcon, setOffset)
	}, [emitter, setOffset])

	useLayoutEffect(() => {
		if (topNavRef && topNavRef.current) {
			const {
				current: { clientWidth }
			} = topNavRef

			// Account for the right-side offset of the orchestrator container
			const containerWidth = clientWidth + TOP_NAV_RIGHT_SPACING

			setTopNavWidth(containerWidth)
		}
	}, [topNavWidth, emitter])

	return (
		<div className={classes.container} ref={topNavRef}>
			<Popover
				content={<SupportMenu />}
				dataTag='support-menu'
				placement='bottomRight'
			>
				<div className={classes.supportMenuContainer}>
					<FontAwesomeIcon icon={faQuestionCircle} size='lg' />
				</div>
			</Popover>
			<Popover
				classes={[classes.popover]}
				content={
					<AccountInfoMenu
						closeMenu={closeMenu}
						profileInfo={profileInfo}
					/>
				}
				dataTag='account-info'
				onVisibleChange={handleAccountMenuOpen}
				placement='bottomRight'
				visible={accountMenuOpen}
			>
				{isEmpty(profileInfo) || profileInfo.profileApiError ? (
					<FontAwesomeIcon
						className={classes.accountInfoContainer}
						icon={faGear}
						size='lg'
					/>
				) : (
					<div className={classes.accountInfoContainer}>
						<ProfileAvatar
							fontSize={20}
							name={firstName}
							size={30}
						/>
					</div>
				)}
			</Popover>
		</div>
	)
}

export default Header
