import {
	FetchProfileRequestAction,
	LoginAction,
	LoginRequestAction,
	LogoutAction,
	SetLoginErrorMsgAction,
	SetProfileApiErrorAction,
	SetProfileInfoAction,
	SetRedirectUrlAction,
	SetServiceMapAction,
	SetServiceMapLoadingAction
} from 'actions'

export enum ActionTypes {
	fetchProfileRequest = 'fetchProfileRequest',
	login = 'login',
	loginRequest = 'loginRequest',
	logout = 'logout',
	setLoginErrorMsg = 'setLoginErrorMsg',
	setOrgInfo = 'setOrgInfo',
	setProfileApiError = 'setProfileApiError',
	setProfileInfo = 'setProfileInfo',
	setRedirectUrl = 'setRedirectUrl',
	setServiceMap = 'setServiceMap',
	setServiceMapLoading = 'setServiceMapLoading'
}

export type Action =
	| FetchProfileRequestAction
	| LoginAction
	| LoginRequestAction
	| LogoutAction
	| SetLoginErrorMsgAction
	| SetProfileApiErrorAction
	| SetProfileInfoAction
	| SetRedirectUrlAction
	| SetServiceMapAction
	| SetServiceMapLoadingAction
