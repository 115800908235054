import { combineReducers } from 'redux'
import authReducer, { AuthState } from 'reducers/auth'
import profileReducer, { ProfileState } from 'reducers/profile'
import serviceMapReducer, { ServiceMapState } from 'reducers/serviceMap'

export interface StoreState {
	auth: AuthState
	profile: ProfileState
	serviceMap: ServiceMapState
}

export default combineReducers<StoreState>({
	auth: authReducer,
	profile: profileReducer,
	serviceMap: serviceMapReducer
})
