import { Service } from 'api'
import { ThemeType } from '@dassana-io/web-components'

export const isDarkMode = (themeType: ThemeType) => themeType === ThemeType.dark

export const getApiUrlFromServiceMap = (
	serviceMap: Service[],
	service: string
) => {
	const config = serviceMap.find(({ serviceName }) => serviceName === service)

	if (!config) {
		throw new Error('Service does not exist in service map')
	}

	return config.apiUrl
}
