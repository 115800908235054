import { commonMagicLinkStyles } from './styles'
import { createUseStyles } from 'react-jss'
import { unstable_batchedUpdates } from 'react-dom'
import { useAppContext } from 'context/AppContext'
import { useHistory } from 'react-router-dom'
import { useMagicLinkContext } from 'context/MagicLinkContext'
import { useTheme } from '@dassana-io/web-utils'
import { Button, styleguide } from '@dassana-io/web-components'
import React, { FC } from 'react'

const { spacing } = styleguide

const useStyles = createUseStyles({
	...commonMagicLinkStyles,
	button: {
		minHeight: 40,
		minWidth: 160
	},
	container: {
		...commonMagicLinkStyles.container,
		padding: spacing['m+']
	},
	subTitle: {
		...commonMagicLinkStyles.subTitle,
		marginBottom: 40
	}
})

const MagicLinkExpired: FC = () => {
	const { emitter } = useAppContext()
	const { setIsErrorState, setMagicLinkSent } = useMagicLinkContext()

	const history = useHistory()

	const theme = useTheme(emitter)
	const classes = useStyles(theme)

	const handleClick = () => {
		unstable_batchedUpdates(() => {
			setMagicLinkSent(false)
			setIsErrorState(false)
		})

		history.push('/')
	}

	return (
		<div className={classes.container}>
			<h2 className={classes.title}>Magic link has expired</h2>
			<Button classes={[classes.button]} onClick={handleClick}>
				Request a new one
			</Button>
		</div>
	)
}

export default MagicLinkExpired
