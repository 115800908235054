import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations from './translations'

i18n
	.use(initReactI18next)
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: process.env.NODE_ENV === 'development',
		fallbackLng: 'en-US',
		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		},
		lng: 'en-US',
		react: {
			useSuspense: false
		},
		resources: translations
	})

export default i18n
