import { ActionTypes } from 'actions/types'
import { Service } from 'api'

export interface SetServiceMapAction {
	serviceMap: Service[]
	type: ActionTypes.setServiceMap
}

export function setServiceMap(serviceMap: Service[] = []): SetServiceMapAction {
	return {
		serviceMap,
		type: ActionTypes.setServiceMap
	}
}

export interface SetServiceMapLoadingAction {
	loading: boolean
	type: ActionTypes.setServiceMapLoading
}

export function setServiceMapLoading(
	loading = false
): SetServiceMapLoadingAction {
	return {
		loading,
		type: ActionTypes.setServiceMapLoading
	}
}
