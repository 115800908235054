import { createUseStyles } from 'react-jss'
import { faEnvelopeOpenText } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@dassana-io/web-components'
import { useAppContext } from 'context/AppContext'
import { useMagicLinkContext } from 'context/MagicLinkContext'
import { useTheme } from '@dassana-io/web-utils'
import { commonMagicLinkStyles, loginPalette } from './styles'
import React, { FC } from 'react'

const useStyles = createUseStyles({
	...commonMagicLinkStyles,
	email: {
		fontWeight: 600,
		letterSpacing: 0.6,
		wordBreak: 'break-word'
	}
})

const MagicLinkConfirmation: FC = () => {
	const { emitter } = useAppContext()
	const { email, setMagicLinkSent } = useMagicLinkContext()

	const theme = useTheme(emitter)

	const classes = useStyles(theme)

	return (
		<div className={classes.container}>
			<div className={classes.content}>
				<FontAwesomeIcon
					color={loginPalette[theme].magicLink.title.color}
					icon={faEnvelopeOpenText}
					size='3x'
				/>
				<h2 className={classes.title}>Check your email</h2>
				<p className={classes.subTitle}>
					We&apos;ve sent a magic link to
					<br />
					<strong className={classes.email}>{email}</strong>
				</p>
			</div>
			<div className={classes.helpText}>
				If you cannot find the email, try checking your spam folder.
				<br />
				If you entered the wrong email address,{' '}
				<Link onClick={() => setMagicLinkSent(false)}>
					re-enter here
				</Link>
				.
			</div>
		</div>
	)
}

export default MagicLinkConfirmation
