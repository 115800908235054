const translations = {
	'en-US': {
		translation: {
			alertManager: 'Alert Manager',
			appStore: 'Sources',
			assets: 'Assets',
			billing: 'Billing',
			copyright: `Copyright © ${new Date().getFullYear()} Dassana Inc.`,
			dark_mode: 'Dark Mode',
			detections: 'Detections',
			findings: 'Findings',
			google_sign_in: 'Sign in with Google',
			integrations: 'Destinations',
			magic_link_sign_in: 'Send me a magic link',
			manage_profile: 'Manage Profile',
			notificationRules: 'Notification Rules',
			orgManager: 'Org Manager',
			profile: 'Profile',
			query: 'Query',
			signout: 'Sign Out',
			user_not_found: 'User not found',
			visualize: 'Dashboard',
			welcome: 'Welcome to Dassana!'
		}
	}
}

export default translations
