import { getBaseUrl } from 'components/utils'

export const BASE_URL = `https://auth.${getBaseUrl()}`

// Endpoints
export const BILLING_PORTAL_ENDPOINT = '/billing/portal'
export const JWT_ENDPOINT = '/token'
export const MAGIC_LINK_ENDPOINT = '/magic/generate'
export const ORG_ENDPOINT = '/org'
export const PROFILE_ENDPOINT = '/profile/v2'
export const RECAPTCHA_ENDPOINT = '/magic/validate'
export const QUERY_READY_ENDPOINT = '/queryable'
export const SERVICES_ENDPOINT = '/services'
export const TENANT_ENDPOINT = '/tenant'

// Interfaces
export * from './auth'
export type { Org, UpsertOrg } from './orgManager'
export type { GetProfileV2 } from './profile'
export type { QueryableResponse } from './query'
export * from './serviceMap'
