import { createUseStyles } from 'react-jss'
import { getName } from './utils'
import { isDarkMode } from 'utils/common'
import LogoutButton from '../LogoutButton'
import ProfileAvatar from './ProfileAvatar'
import { ProfileState } from 'reducers/profile'
import React from 'react'
import { useHistory } from 'react-router-dom'
import useThemeContext from 'context/ThemeContext'
import { useTranslation } from 'react-i18next'
import {
	Button,
	styleguide,
	Theme,
	ThemeType,
	Toggle
} from '@dassana-io/web-components'

const { dark, light } = ThemeType
const {
	flexAlignCenter,
	flexDown,
	flexJustifyCenter,
	flexSpaceBetween,
	font,
	spacing
} = styleguide

const useStyles = createUseStyles({
	container: {
		...flexDown,
		background: (theme: Theme) => theme.background.secondary,
		color: (theme: Theme) => theme.text.primary,
		padding: `${spacing.m}px 0`,
		width: '250px'
	},
	controlsContainer: {
		...flexJustifyCenter,
		padding: {
			bottom: spacing.xs,
			top: spacing.m
		}
	},
	info: {
		...flexAlignCenter,
		...flexDown
	},
	name: {
		...font.body,
		padding: `${spacing.m}px 0`,
		textAlign: 'center'
	},
	profile: {
		...flexAlignCenter,
		...flexDown,
		...flexSpaceBetween,
		padding: spacing.m
	},
	settings: {
		...flexSpaceBetween,
		borderBottom: (theme: Theme) => `1px solid ${theme.border}`,
		borderTop: (theme: Theme) => `1px solid ${theme.border}`,
		padding: spacing.m
	}
})

interface Props {
	profileInfo: ProfileState
	closeMenu: () => void
}

const AccountInfoMenu: React.FC<Props> = ({
	profileInfo,
	closeMenu
}: Props) => {
	const { setThemeType, theme, themeType } = useThemeContext()
	const history = useHistory()
	const classes = useStyles(theme)
	const { t } = useTranslation()

	const { firstName } = profileInfo

	const onDarkMode = isDarkMode(themeType)

	const handleClickThemeSwitcher = () => {
		setThemeType(onDarkMode ? light : dark)
	}

	const handleProfileLinkClick = () => {
		history.push('/profile')
		closeMenu()
	}

	return (
		<div className={classes.container}>
			{!profileInfo.profileApiError && (
				<div className={classes.profile}>
					<ProfileAvatar fontSize={40} name={firstName!} size={75} />
					<div className={classes.name}>{getName(profileInfo)}</div>
					<Button
						dataTag='manage-profile'
						onClick={handleProfileLinkClick}
					>
						{t('manage_profile')}
					</Button>
				</div>
			)}
			<div className={classes.settings}>
				<span>{t('dark_mode')}</span>
				<Toggle
					checked={onDarkMode}
					dataTag='theme-toggle'
					onChange={handleClickThemeSwitcher}
				/>
			</div>
			<div className={classes.controlsContainer}>
				<LogoutButton />
			</div>
		</div>
	)
}

export default AccountInfoMenu
