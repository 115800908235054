import { convertEpochToEpochMs } from 'utils/saga'
import { logout } from 'actions'
import { TOKEN } from 'sagas/constants'
import { delay, put } from 'redux-saga/effects'
import jwtDecode, { JwtPayload } from 'jwt-decode'

// This saga is responsible for cross tab communication
// When a user logs out on one tab, they should also be logged out on all other tabs
export default function* detectTokenPresenceSaga() {
	while (true) {
		yield delay(1000)

		const token = localStorage.getItem(TOKEN)

		if (!token) {
			yield put(logout())
		} else {
			try {
				const { exp } = jwtDecode<JwtPayload>(token)

				// Log user out if token is expired
				if (Date.now() > convertEpochToEpochMs(exp!))
					yield put(logout())
			} catch (error) {
				// Log user out if token is invalid and unable to be parsed
				yield put(logout())
			}
		}
	}
}
