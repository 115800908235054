/* eslint-disable */

// src: https://gist.github.com/JenniferFuBook/0cd7d34548c76df1837988692880b54c
import { createUseStyles } from 'react-jss'
import { MICRO_FE_CONTAINER_ID } from '@dassana-io/web-utils'
import { PageLoader, styleguide } from '@dassana-io/web-components'
import React, { useEffect, useState } from 'react'

const { flexAlignCenter, topNavHeight } = styleguide

const useStyles = createUseStyles({
	microFeLoader: {
		...flexAlignCenter,
		height: '100vh',
		paddingRight: topNavHeight,
		width: '100%'
	}
})

const MicroFrontend = ({
	apiUrl,
	containerId = MICRO_FE_CONTAINER_ID,
	dassanaEmitter,
	name,
	host,
	history,
	serviceMap,
	showLoader = false
}) => {
	const [loading, setLoading] = useState(showLoader)
	const classes = useStyles()

	useEffect(() => {
		const scriptId = `micro-frontend-script-${name}`
		const renderMicroFrontend = () => {
			window[`render${name}`] &&
				window[`render${name}`](
					containerId,
					apiUrl,
					history,
					dassanaEmitter,
					serviceMap
				)
		}

		if (document.getElementById(scriptId)) {
			setTimeout(renderMicroFrontend, 0)
			setLoading(false)

			return
		}

		fetch(`${host}/asset-manifest.json`)
			.then(res => res.json())
			.then(manifest => {
				const promises = Object.keys(manifest['files'])
					.filter(key => key.endsWith('.js'))
					.reduce((sum, key) => {
						sum.push(
							new Promise(resolve => {
								const path = `${host}${manifest['files'][key]}`
								const script = document.createElement('script')

								if (key === 'main.js') {
									script.id = scriptId
								}

								script.onload = () => {
									resolve()
								}

								script.src = path

								document.body.after(script)
							})
						)

						return sum
					}, [])

				Promise.allSettled(promises).then(() => {
					setLoading(false)
					renderMicroFrontend()
				})
			})
	}, [name, host, history, dassanaEmitter, apiUrl, serviceMap])

	useEffect(() => {
		return () => {
			window[`unmount${name}`] && window[`unmount${name}`](containerId)
		}
	}, [])

	return <>{loading && <PageLoader classes={[classes.microFeLoader]} />}</>
}

export default MicroFrontend
