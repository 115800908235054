import { createUseStyles } from 'react-jss'
import LoginButton from './LoginButton'
import { loginPalette } from './styles'
import MagicLinkFollowup from './MagicLinkFollowup'
import MagicLinkForm from './MagicLinkForm'
import React from 'react'
import { StoreState } from 'reducers'
import { useAppContext } from 'context/AppContext'
import { useMagicLinkContext } from 'context/MagicLinkContext'
import { useSelector } from 'react-redux'
import { useTheme } from '@dassana-io/web-utils'
import { styleguide, ThemeType } from '@dassana-io/web-components'

const { flexCenter, flexDown, spacing } = styleguide

const useStyles = createUseStyles({
	container: {
		...flexCenter,
		...flexDown,
		backgroundColor: (theme: ThemeType) =>
			loginPalette[theme].loginActions.container.background,
		minHeight: 400,
		padding: 40,
		width: 400
	},
	divider: {
		border: (theme: ThemeType) =>
			`1px solid ${loginPalette[theme].loginActions.divider.borderColor}`,
		margin: {
			bottom: spacing.xl,
			top: spacing.l
		}
	},
	dividerContainer: {
		width: '95%'
	},
	loginError: {
		height: '50px',
		paddingTop: spacing.xl
	}
})

const LoginActions = () => {
	const { emitter } = useAppContext()
	const { magicLinkSent } = useMagicLinkContext()

	const theme = useTheme(emitter)

	const loginError = useSelector(
		(state: StoreState): string => state.auth.errorMsg
	)

	const classes = useStyles(theme)

	return (
		<div className={classes.container}>
			{magicLinkSent ? (
				<MagicLinkFollowup />
			) : (
				<>
					<LoginButton />
					{loginError && (
						<div className={classes.loginError}>{loginError}</div>
					)}
					<div className={classes.dividerContainer}>
						<hr className={classes.divider} />
					</div>
					<MagicLinkForm />
				</>
			)}
		</div>
	)
}

export default LoginActions
