import { createCtx } from '@dassana-io/web-utils'
import { ReCaptchaResponse } from 'api'

export type RecaptchaValue = ReCaptchaResponse | null

export interface MagicLinkContextProps {
	email: string
	isErrorState: boolean
	magicLinkSent: boolean
	setEmail: (email: string) => void
	setIsErrorState: (isErrorState: boolean) => void
	setMagicLinkSent: (magicLinkSent: boolean) => void
}

export const [useMagicLinkContext, MagicLinkContextProvider] =
	createCtx<MagicLinkContextProps>()
