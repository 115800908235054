import { Button } from '@dassana-io/web-components'
import { Dispatch } from 'redux'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { logout, LogoutAction } from 'actions'

const LogoutButton: React.FC = () => {
	const dispatch: Dispatch<LogoutAction> = useDispatch()
	const { t } = useTranslation()

	const onLogout = (): LogoutAction => dispatch(logout())

	return (
		<Button dataTag='logout' onClick={onLogout}>
			{t('signout')}
		</Button>
	)
}

export default LogoutButton
