import { styleguide, Theme, ThemeType } from '@dassana-io/web-components'

const {
	colors: { grays, blacks, whites },
	flexJustifyCenter,
	font,
	spacing
} = styleguide

export interface UseStylesProps {
	showPageKeyHint?: boolean
	theme: Theme
}

export const commonNavLinkStyles = {
	icon: {
		color: ({ theme }: UseStylesProps) => theme.text.primary
	},
	iconContainer: {
		...flexJustifyCenter,
		position: 'relative',
		width: '100%'
	},
	navItem: {
		'&:hover': {
			'& svg': {
				fill: ({ theme }: UseStylesProps) => theme.state.active
			}
		},
		...flexJustifyCenter,
		cursor: 'pointer',
		margin: `${spacing.m}px 0`
	},
	pageKeyHint: {
		background: ({ theme }: UseStylesProps) => theme.background.secondary,
		borderRadius: 4,
		bottom: -12,
		color: ({ theme }: UseStylesProps) => theme.text.primary,
		fontSize: font.label.fontSize,
		opacity: ({ showPageKeyHint }: UseStylesProps) =>
			showPageKeyHint ? 1 : 0,
		padding: 4,
		position: 'absolute',
		right: 4
	}
}

export const supportMenuPalette = {
	container: {
		[ThemeType.dark]: {
			background: blacks.base
		},
		[ThemeType.light]: {
			background: whites.base
		}
	},
	menuItem: {
		[ThemeType.dark]: {
			color: blacks['lighten-50'],
			hoverBackground: blacks['lighten-30']
		},
		[ThemeType.light]: {
			color: blacks['lighten-30'],
			hoverBackground: grays['lighten-40']
		}
	}
}
