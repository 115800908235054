import axios from 'axios'
import { createUseStyles } from 'react-jss'
import { FieldValues } from 'react-hook-form'
import { getRecaptchaApiKey } from './utils'
import ReCAPTCHA from 'react-google-recaptcha'
import { unstable_batchedUpdates } from 'react-dom'
import { useAppContext } from 'context/AppContext'
import { useMagicLinkContext } from 'context/MagicLinkContext'
import { useTranslation } from 'react-i18next'
import { BASE_URL, MAGIC_LINK_ENDPOINT } from 'api'
import { EMAIL_REGEX, handleAjaxErrors, useTheme } from '@dassana-io/web-utils'
import {
	Form,
	getDataTestAttributeProp,
	styleguide
} from '@dassana-io/web-components'
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'

const {
	colors: { blues, whites }
} = styleguide

const useStyles = createUseStyles({
	magicLinkButton: {
		'&:hover': {
			opacity: 0.8
		},
		backgroundColor: `${blues.base} !important`,
		border: 'none',
		color: `${whites.base} !important`,
		width: '100%'
	},
	recaptcha: {
		paddingBottom: '36px !important'
	}
})

const MagicLinkForm: FC = () => {
	const { emitter } = useAppContext()
	const theme = useTheme(emitter)
	const { email, setEmail, setMagicLinkSent } = useMagicLinkContext()
	const { t } = useTranslation()
	const classes = useStyles()
	const recaptchaRef = useRef<ReCAPTCHA>(null)

	const defaultValues = useMemo(() => ({ email }), [email])

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [recaptchaValue, setRecaptchaValue] = useState('')

	const handleRecaptchaSuccess = useCallback(() => {
		const token = recaptchaRef?.current?.getValue()

		if (token) {
			setRecaptchaValue(token)
		}
	}, [setRecaptchaValue])

	const handleSubmit = async (formValues: FieldValues) => {
		const { email } = formValues
		const formattedEmail = email.toLowerCase()

		if (!recaptchaValue) return

		try {
			setIsSubmitting(true)

			await axios.post(
				MAGIC_LINK_ENDPOINT,
				{ email: formattedEmail, token: recaptchaValue },
				{ baseURL: BASE_URL }
			)

			unstable_batchedUpdates(() => {
				setEmail(formattedEmail)
				setMagicLinkSent(true)
			})
		} catch (error: any) {
			setMagicLinkSent(true)
			handleAjaxErrors(error, emitter)
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<div>
			<Form initialValues={defaultValues} onSubmit={handleSubmit}>
				<Form.Input
					dataTag='magic-link-email'
					fullWidth
					label='Email'
					name='email'
					required
					rules={{
						pattern: {
							message: 'Please enter a valid email',
							value: EMAIL_REGEX
						}
					}}
				/>
				<div {...getDataTestAttributeProp('magic-link-captcha')}>
					<ReCAPTCHA
						className={classes.recaptcha}
						onChange={handleRecaptchaSuccess}
						ref={recaptchaRef}
						sitekey={getRecaptchaApiKey()}
						theme={theme}
					/>
				</div>
				<Form.SubmitButton
					classes={[classes.magicLinkButton]}
					dataTag='magic-link-submit'
					isDisabled={() => isSubmitting}
				>
					{t('magic_link_sign_in')}
				</Form.SubmitButton>
			</Form>
		</div>
	)
}

export default MagicLinkForm
