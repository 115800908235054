import { Avatar } from '@dassana-io/web-components'
import { AvatarProps } from '@dassana-io/web-components/lib/types/Avatar'
import { createUseStyles } from 'react-jss'
import React from 'react'

const useStyles = createUseStyles({
	letter: {
		fontSize: (props: Props) => (props.fontSize ? props.fontSize : 16)
	}
})

interface Props {
	fontSize?: number
	name: string
	size?: AvatarProps['size']
}

const ProfileAvatar: React.FC<Props> = (props: Props) => {
	const { name, size = 'default' } = props

	const classes = useStyles(props)

	return (
		<Avatar size={size}>
			<div className={classes.letter}>{`${name.charAt(0)}`}</div>
		</Avatar>
	)
}

export default ProfileAvatar
