import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavLinkTooltipTitle from './NavLinkTooltipTitle'
import { Services } from '@dassana-io/web-utils'
import { Tooltip } from '@dassana-io/web-components'
import useThemeContext from 'context/ThemeContext'
import { active, getServiceIcon, inactive } from './utils'
import { commonNavLinkStyles, UseStylesProps } from './styles'
import React, { FC } from 'react'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'

const useStyles = createUseStyles({
	...commonNavLinkStyles,
	activeNavItem: {
		'& $icon': {
			color: ({ theme }: UseStylesProps) => theme.state.active
		},
		'& $iconContainer': {
			borderRight: ({ theme }: UseStylesProps) =>
				`3px solid ${theme.state.active}`
		},
		cursor: 'not-allowed',
		pointerEvents: 'none'
	}
})

export type ValidPagesMap = Record<string, string>

interface NavLinkProps {
	pageKey: number
	serviceName: string
	showPageKeyHint: boolean
}

const NavLink: FC<NavLinkProps> = ({
	pageKey,
	serviceName,
	showPageKeyHint
}: NavLinkProps) => {
	const { theme } = useThemeContext()
	const classes = useStyles({ showPageKeyHint, theme })

	const location = useLocation()
	const isActive = location.pathname.includes(serviceName)

	return (
		<RouterLink
			activeClassName={classes.activeNavItem}
			className={classes.navItem}
			isActive={() => isActive}
			key={serviceName}
			to={`/${serviceName}`}
		>
			<span className={classes.iconContainer}>
				<Tooltip
					title={
						<NavLinkTooltipTitle
							pageKey={pageKey}
							serviceName={serviceName}
						/>
					}
				>
					<FontAwesomeIcon
						className={classes.icon}
						height={24}
						icon={getServiceIcon(
							serviceName as Services,
							isActive ? active : inactive
						)}
					/>
				</Tooltip>
				<span className={classes.pageKeyHint}>{pageKey}</span>
			</span>
		</RouterLink>
	)
}

export default NavLink
