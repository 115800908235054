import { styleguide, ThemeType } from '@dassana-io/web-components'

const {
	colors: { blacks, grays, whites },
	flexCenter,
	flexDown,
	font,
	fontWeight,
	spacing
} = styleguide

export const loginPalette = {
	[ThemeType.dark]: {
		loginActions: {
			container: {
				background: blacks.base
			},
			divider: {
				borderColor: blacks['lighten-10']
			}
		},
		magicLink: {
			supportText: {
				color: blacks['lighten-50']
			},
			title: {
				color: whites.base
			}
		}
	},
	[ThemeType.light]: {
		loginActions: {
			container: {
				background: grays.base
			},
			divider: {
				borderColor: blacks['lighten-70']
			}
		},
		magicLink: {
			supportText: {
				color: blacks['lighten-30']
			},
			title: {
				color: blacks['lighten-20']
			}
		}
	}
}

export const commonMagicLinkStyles = {
	container: {
		...flexCenter,
		...flexDown,
		fontWeight: fontWeight.light,
		lineHeight: `${spacing.l}px`,
		padding: {
			bottom: spacing.xl,
			top: spacing.xl
		},
		textAlign: 'center'
	},
	content: {
		marginBottom: 40
	},
	helpText: {
		color: (theme: ThemeType) =>
			loginPalette[theme].magicLink.supportText.color,
		fontSize: font.label.fontSize
	},
	subTitle: {
		color: (theme: ThemeType) =>
			loginPalette[theme].magicLink.supportText.color,
		fontSize: font.body.fontSize,
		margin: { bottom: 0, top: 0 }
	},
	title: {
		color: (theme: ThemeType) => loginPalette[theme].magicLink.title.color,
		fontWeight: fontWeight.light,
		letterSpacing: 0.6,
		lineHeight: 1.2,
		margin: {
			bottom: spacing.xl,
			top: spacing.xl
		}
	}
}
