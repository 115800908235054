// import Banner from './Banner'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import Header from 'components/Nav/Header'
import { isDarkMode } from 'utils/common'
import { MICRO_FE_CONTAINER_ID } from '@dassana-io/web-utils'
import Nav from 'components/Nav'
import React from 'react'
import { StoreState } from 'reducers'
import { useSelector } from 'react-redux'
import useThemeContext from 'context/ThemeContext'
import { BANNER_CONTAINER_ID, NAV_DIMENSIONS } from 'utils/constants'
import { PageLoader, styleguide, Theme } from '@dassana-io/web-components'

const { flexDown } = styleguide
interface Props {
	children: JSX.Element
}

const useStyles = createUseStyles({
	container: {
		background: (theme: Theme) => theme.background.primary,
		color: (theme: Theme) => theme.text.primary,
		display: 'flex',
		height: '100%',
		width: '100%'
	},
	contentContainer: {
		...flexDown,
		background: (theme: Theme) => theme.background.primary,
		boxShadow: (theme: Theme) => `inset 2px 0 10px -6px ${theme.border}`,
		flexGrow: 1,
		position: 'relative',
		width: `calc(100vw - ${NAV_DIMENSIONS})`
	},
	hasContent: {
		'&:not(:empty)': {
			height: '100vh'
		}
	},
	microFeContainer: {
		display: 'flex'
	}
})

const PageContainer: React.FC<Props> = ({ children }: Props) => {
	const { themeType, theme } = useThemeContext()

	const isAuthenticated = useSelector(
		(state: StoreState): boolean => state.auth.loggedIn
	)
	const isServiceMapLoading = useSelector(
		(state: StoreState): boolean => state.serviceMap.loading
	)

	const classes = useStyles(theme)

	const containerClasses = cn({
		[classes.container]: true,
		dark: isDarkMode(themeType)
	})

	const microFeContainerClasses = cn({
		[classes.microFeContainer]: true,
		[classes.hasContent]: isAuthenticated
	})

	return (
		<div className={containerClasses}>
			{isServiceMapLoading ? (
				<PageLoader />
			) : (
				<>
					{isAuthenticated && <Nav />}
					<div className={classes.contentContainer}>
						{isAuthenticated && <Header />}
						{/* {isAuthenticated && <Banner />} */}
						{children}
						<header id={BANNER_CONTAINER_ID} />
						<main
							className={microFeContainerClasses}
							id={MICRO_FE_CONTAINER_ID}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default PageContainer
