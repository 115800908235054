import { eventChannel } from 'redux-saga'
import { logout } from 'actions'
import { all, call, delay, put, race, take } from 'redux-saga/effects'
import { INACTIVITY_TIMEOUT, LAST_ACTIVE } from 'sagas/constants'

export function listener(event: string) {
	return eventChannel(emitter => {
		window.addEventListener(event, emitter)

		return () => window.removeEventListener(event, emitter)
	})
}

export default function* inactivityTimeoutSaga(): Generator<any, any, any> {
	// run multiple calls in parallel and wait for all of them to complete
	const [mousemove, mousedown, click, scroll, keypress] = yield all([
		call(listener, 'mousemove'),
		call(listener, 'mousedown'),
		call(listener, 'click'),
		call(listener, 'scroll'),
		call(listener, 'keypress')
	])

	while (true) {
		const { timeout } = yield race({
			click: take(click),
			keypress: take(keypress),
			mousedown: take(mousedown),
			mousemove: take(mousemove),
			scroll: take(scroll),
			timeout: delay(INACTIVITY_TIMEOUT)
		})

		if (timeout) {
			const lastActive = localStorage.getItem(LAST_ACTIVE)

			const otherTabsAreInactive =
				lastActive &&
				Date.now() - parseInt(lastActive) > INACTIVITY_TIMEOUT

			if (!lastActive || otherTabsAreInactive) {
				yield put(logout())
				yield delay(1000)
			}
		} else {
			localStorage.setItem(LAST_ACTIVE, Date.now().toString())
		}
	}
}
