import has from 'lodash/has'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { QUERY_SERVICE_NAME } from 'utils/constants'
import { Services } from '@dassana-io/web-utils'
import {
	faArrowRightFromArc,
	faArrowRightToArc,
	faBellExclamation,
	faBug,
	faChartMixed,
	faCreditCard,
	faSearch,
	faShapes,
	faShieldHalved,
	faSitemap
} from '@fortawesome/pro-light-svg-icons'
import {
	faArrowRightFromArc as faArrowRightFromArcSolid,
	faArrowRightToArc as faArrowRightToArcSolid,
	faBellExclamation as faBellExclamationSolid,
	faBug as faBugSolid,
	faChartMixed as faChartMixedSolid,
	faCreditCard as faCreditCardSolid,
	faSearch as faSearchSolid,
	faShapes as faShapesSolid,
	faShieldHalved as faShieldHalvedSolid,
	faSitemap as faSitemapSolid
} from '@fortawesome/pro-solid-svg-icons'
import { GetProfileV2, Service } from 'api'

export enum ServicesIconState {
	active = 'active',
	inactive = 'inactive'
}

export const { active, inactive } = ServicesIconState

export const SERVICES_MFE_MAP: Record<string, string> = {
	[QUERY_SERVICE_NAME]: 'query'
}

/* eslint-disable sort-keys */
// ** IMPORTANT ** The order of this object determines the order of the nav bar icons
export const SERVICES_TITLE_MAP: Record<string, string> = {
	[Services.visualize]: 'dashboard',
	[QUERY_SERVICE_NAME]: 'query',
	[Services.appStore]: 'apps',
	// alertManager: 'alert',
	// [Services.detections]: 'detections',
	// [Services.notificationRules]: 'notificationRules',
	[Services.integrations]: 'integrations',
	[Services.findings]: 'findings',
	[Services.assets]: 'assets',
	[Services.orgManager]: 'org',
	[Services.billing]: 'billing'
}
/* eslint-enable sort-keys */

type ServiceIconMap = Record<ServicesIconState, IconDefinition>

const SERVICES_ICON_MAP: Partial<Record<Services, ServiceIconMap>> = {
	[Services.assets]: {
		[active]: faShapesSolid,
		[inactive]: faShapes
	},
	[Services.appStore]: {
		[active]: faArrowRightFromArcSolid,
		[inactive]: faArrowRightFromArc
	},
	[Services.billing]: {
		[active]: faCreditCardSolid,
		[inactive]: faCreditCard
	},
	[Services.detections]: {
		[active]: faShieldHalvedSolid,
		[inactive]: faShieldHalved
	},
	[Services.findings]: {
		[active]: faBugSolid,
		[inactive]: faBug
	},
	[Services.integrations]: {
		[active]: faArrowRightToArcSolid,
		[inactive]: faArrowRightToArc
	},
	[Services.notificationRules]: {
		[active]: faBellExclamationSolid,
		[inactive]: faBellExclamation
	},
	[Services.orgManager]: {
		[active]: faSitemapSolid,
		[inactive]: faSitemap
	},
	[Services.query]: {
		[active]: faSearchSolid,
		[inactive]: faSearch
	},
	[Services.visualize]: {
		[active]: faChartMixedSolid,
		[inactive]: faChartMixed
	}
}

const extractPropFromServiceMapConfig = <T>(
	serviceName: string,
	configMap: Record<string, T>
): T =>
	(has(configMap, serviceName) ? configMap[serviceName] : serviceName) as T

export const getServiceIcon = (
	serviceName: Services,
	iconState = ServicesIconState.inactive
) => {
	const serviceIcons =
		SERVICES_ICON_MAP[serviceName] || ({} as ServiceIconMap)

	return serviceIcons[iconState]
}

const getNavbarServices = (serviceMapObject: Record<string, Service>) => {
	const navBarServices: Service[] = []

	Object.keys(SERVICES_TITLE_MAP).forEach(title => {
		if (has(serviceMapObject, title))
			navBarServices.push(serviceMapObject[title])
	})

	return navBarServices
}

const getName = (profileInfo: GetProfileV2): string => {
	const { firstName, lastName } = profileInfo

	return lastName ? `${firstName} ${lastName}` : firstName!
}

const getServiceName = (serviceName: string) =>
	extractPropFromServiceMapConfig<string>(serviceName, SERVICES_MFE_MAP)

const processServiceMap = (serviceMap: Service[]): Record<string, Service> => {
	const processedServiceMap: Record<string, Service> = {}

	serviceMap.forEach(
		service => (processedServiceMap[service.serviceName] = service)
	)

	return processedServiceMap
}

export { getNavbarServices, getName, getServiceName, processServiceMap }
