import { api } from '@dassana-io/web-utils'
import { convertEpochToEpochMs } from 'utils/saga'
import { logout } from 'actions'
import { TOKEN } from 'sagas/constants'
import { BASE_URL, JWT, JWT_ENDPOINT } from 'api'
import { call, delay, put } from 'redux-saga/effects'
import jwtDecode, { JwtPayload } from 'jwt-decode'

export const getRefreshToken = async (): Promise<string> => {
	const {
		data: { id_token }
	} = await api(BASE_URL).patch<JWT>(JWT_ENDPOINT, {
		id_token: localStorage.getItem(TOKEN)
	})

	return id_token
}

export default function* refreshTokenSaga() {
	while (true) {
		const token = localStorage.getItem(TOKEN)

		if (token) {
			const { exp } = jwtDecode<JwtPayload>(token)

			yield delay(convertEpochToEpochMs(exp!) - Date.now() - 30000)
		}

		try {
			const refreshToken: string = yield call(getRefreshToken)

			localStorage.setItem(TOKEN, refreshToken)
		} catch (error: any) {
			yield put(logout())
		}
	}
}
